@use 'base' as *;

* {
  box-sizing: border-box;
  font-family: "Maison Neue", sans-serif;
  font-weight: normal;
  overscroll-behavior: none;
  // scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  background-color: $black;
  color: $black;
  font-size: $unit;
  position: relative;
}

.cursor {
  cursor: pointer;
  height: 35px;
  width: 35px;
  position: absolute;
  z-index: 50;
  top: 0;
  background: $lightgray;
  border-radius: 100%;
  pointer-events: none;
  mix-blend-mode: difference;
  @media ($phone) {
    display: none;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: $black;
}

h2 {
  font-family: "Maison Neue Extended";
  font-weight: normal;
  text-transform: uppercase;
  font-size: 40px;
  margin: 0;
}

.nav-container {
  background-color: $black;
  color: $lightgray;
  position: fixed;
  top: 0;
  height: 55px;
  width: 100vw;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  @media ($phone) {
    margin: 0 AUTO 0 AUTO;
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      list-style-type: none;
      margin: 0 ($unit * 2) 0 ($unit * 2);
      @media ($phone) {
        margin: 0 $unit 0 $unit;
      }
    }
    svg {
      margin: 0 $unit -3px $unit;
      @media ($phone) {
        margin: 0;
        width: 100px;
        height: 100px;
      }
    }
    &.backnext {
      margin: 2px 0 0 0;
      @media ($phone) {
        display: none;
      }
    }
  }
  a {
    font-family: "Maison Neue Extended";
    font-size: $unit * 0.8;
    font-weight: 500;
    letter-spacing: 1px;
    color: $lightgray;
    &.close {
      display: flex;
      // background-color: green;
      position: absolute;
      left: 24px;
      span {
            font-family: "Maison Neue Extended";

        margin: 8px 0 0 $unit;
        // background-color: red;
        @media ($phone) {
          margin: 6px 0 0 16px;
        }
      }
      svg {
        // background-color: yellow;
        max-width: 30px;
      }
      @media ($phone) {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }

  .home {
    position: absolute;
    left: 53px;
    @media ($phone) {
      position: static;
      left: 24px;
      width: 25px;
    }
  }
}

.panel-background {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 999;
  background-color: $black;
  right: 0;
  pointer-events: none;
  &.green {
    background-color: $green;
  }
  &.yellow {
    background-color: $yellow;
  }
  &.blue {
    background-color: $blue;
  }
  &.red {
    background-color: $red;
  }
}

.panel-test {
  height: 100vh;
  width: 100vw;
  position: fixed;
  // z-index: 999;
  background-color: $black;
  right: 0;
  pointer-events: none;
}

@keyframes scale-up-center {
  0% {
    height: 0;
    transform: scale(0.5);
  }
  100% {
    height: 6px;
    transform: scale(1);
  }
}

.circlehome,
.circlework,
.circleplay,
.circleabout {
  margin: 3px auto -3px auto;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  animation: scale-up-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.circlehome {
  background-color: $red;
  @media ($phone) {
    margin: 2px 0 0 7px;
  }
}

.circlework {
  background-color: $green;
}

.circleplay {
  background-color: $yellow;
}

.circleabout {
  background-color: $blue;
}

.main-container-home,
.main-container-work,
.main-container-play,
.main-container-about,
.main-container-project {
  // z-index: 11;
  // position: relative;
  padding-top: 55px;
  background-color: $red;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main-container-home {
  .page-title,
  .social-links {
    position: relative;
    z-index: 5;
  }
}

.main-container-work {
  background-color: $green;
}

.main-container-play {
  background-color: $yellow;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.main-container-project {
  background-color: $lightgray;
  display: block;
  height: 100%;
  overflow: visible;
  // @media ($phone) {
  //     overflow: visible;
  // }
}

.video-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  @media ($phone) {
    width: 100vw;
    height: 100vh;
  }
}

.mask {
  overflow: hidden;
}

.moving-mask {
  z-index: 10;
  background: $red;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  &.blue {
    background: $blue;
  }
  @media ($phone) {
    width: auto;
    height: auto;
  }
}

.page-title {
  padding-top: $unit * 4;
  width: $unit * 8;
  // background-color: #ffad77;
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: $unit;
  height: 10vh;
  user-select: none;
  p {
    font-family: "Maison Neue Extended";
    font-weight: 500;
  }
  &.project {
    // background-color: red;
    // align-items: flex-start;
    letter-spacing: 2px;
    // writing-mode: horizontal-tb;
    // text-orientation: mixed;
    p {
      margin-top: -10px;
    }
  }
  @media ($phone) {
    display: none;
  }
}

.social-links {
  // background-color: #ffe377;
  flex: 0 0 50%;
  height: 10vh;
  width: $unit * 8;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  span {
    padding: 0;
    margin: $unit;
    cursor: pointer;
  }
  .line {
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    margin: $unit auto 0px auto;
    background-color: $black;
    &:before {
      content: "";
      margin: -3px 0 0 -3px;
      position: absolute;
      height: 7px;
      width: 7px;
      border-radius: 100%;
      background: $black;
    }
  }
  @media ($phone) {
    display: none;
  }
}

.home-content {
  padding-top: $unit * 4;
  // margin: auto;
  // background-color: #77faff;
  flex: 0 0 100%;
  width: calc(100vw - 128px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 80px 0 0;
  @media ($phone) {
    width: 100vw;
  }
  .home-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      z-index: 4;
      max-width: 1000px;
      h1 {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $black;
        color: rgba(255, 255, 255, 0);
        padding-left: $unit * 3;
        font-family: "Maison Neue Extended";
        text-align: right;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-size: $unit * 5;
        line-height: 1;
        margin: 0 0 16px 0;
        span {
          font-family: "Maison Neue Extended";
          font-weight: bold;
          letter-spacing: 2px;
          color: $black;
        }
        @media ($phone) {
          text-align: left;
          font-size: 40px;
          width: auto;
          padding: 0 32px;
        }
      }
      p {
        font-family: "Maison Neue Extended";
        font-weight: normal;
        width: 600px;
        text-align: right;
        font-size: $unit * 1.2;
        line-height: 1.8;
        margin: 0 0 0 auto;
        span {
          font-family: "Maison Neue Extended";
          font-weight: normal;
          border-bottom: 2px solid $black;
          cursor: crosshair;
        }
        @media ($phone) {
          text-align: left;
          font-size: 14px;
          width: auto;
          padding: 8px 32px;
        }
      }
    }
    .image {
      img {
        height: 500px;
        @media ($phone) {
          padding-top: $unit * 3;
          padding-bottom: $unit;
          height: 250px;
        }
      }
    }
    @media ($phone) {
      width: 100vw;
      height: calc(100vh - 60px);
      padding-top: $unit * 3;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  @media ($phone) {
    display: block;
    padding: 0;
  }
}

.project-titles {
  padding-top: $unit * 4;
  // margin: auto;
  // background-color: #77faff;
  flex: 0 0 100%;
  width: calc(100vw - 128px);
  display: flex;
  flex-direction: column;
  // z-index: 1;
  justify-content: center;
  h1 {
    position: relative;
  }
  @media ($phone) {
    display: none;
  }
  .project {
    color: $black;
    width: 80%;
    min-width: 300px;
    max-width: 800px;
    display: flex;
    align-items: center;
    // background-color: red;
    margin: 0 0 0 auto;
    h1 {
      font-family: "Maison Neue Extended";
      font-weight: bold;
      text-transform: uppercase;
      font-size: $unit * 6;
      margin: $unit 0;
      // z-index: 10;
      &:hover {
        font-family: "Maison Neue Extended";
        cursor: pointer;
        color: $transparent;
        -webkit-text-stroke: 1px black;
      }
      transition: all 0.2s ease-in-out;
      @media ($small) {
        font-size: $unit * 4;
      }
    }
    .line {
      width: 100px;
      height: 1px;
      margin: $unit auto 0px auto;
      background-color: $black;
      margin: 0 0 0 16px;
      flex: 1;
      &:before {
        content: "";
        margin: -3px 0 0 0px;
        position: absolute;
        height: 7px;
        width: 7px;
        border-radius: 100%;
        background: $black;
      }
      &.one,
      &.two,
      &.three {
        &:after {
          font-size: 10px;
          content: "App Concept";
          margin: -12px 0 0 24px;
          position: absolute;
          padding: 6px 12px;
          background-color: $green;
          border-radius: 15px;
          border: 1px solid $black;
        }
      }
      &.two {
        &:after {
          content: "App Concept";
        }
      }
      &.three {
        &:after {
          content: "Feature Concept";
        }
      }
    }
  }
}

.project-video {
  position: absolute;
  pointer-events: none;
  height: 400px;
  // border-radius: 20px;
  overflow: hidden;

  z-index: 999;
}

.project-titles-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  .project {
    h1 {
      font-family: "Maison Neue Extended";
      font-weight: bold;
      text-transform: uppercase;
      font-size: 40px;
      margin: $unit ($unit * 2);
    }
    a {
      color: $black;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // background-color: red;
    }
    svg {
      width: 50px;
      margin: -5px 16px 0 0;
    }
    .line {
      display: block;
      width: 100vw;
      height: 1px;
      margin: 26px auto 26px auto;
      background-color: $black;
      &.one,
      &.two,
      &.three {
        &:after {
          font-size: 10px;
          content: "App Concept";
          margin: -12px 0 0 24px;
          position: absolute;
          padding: 6px 12px;
          background-color: $green;
          border-radius: 15px;
          border: 1px solid $black;
        }
      }
      &.two {
        &:after {
          content: "App Concept";
        }
      }
      &.three {
        &:after {
          content: "Feature Concept";
        }
      }
    }
  }
  @media ($desktop) {
    display: none;
  }
}

.noclicks {
  pointer-events: none;
}

.example-container div {
  background: white;
  border-radius: 30px;
  width: 150px;
  height: 150px;
}
