@use 'base' as *;

.videoThumbnail {
  cursor: pointer;
}
.video {
  position: relative;
  .preview {
    border-radius: 0px;
  }
  .badgecontainer {
    position: absolute;
    bottom: -24px;
    right: 0px;
    display: flex;
    transition: 0.4s ease-in-out;

  }
  .badge {
    margin-left: 6px;
    position: relative;
    background-color: $black;
    color: $lightgray;
    border: 1px solid $black;
    // border-radius: 15px;
    padding: 0 12px;
    // height: 16px;
    line-height: 16px;
    font-size: 10px;
    transition: 0.4s ease-in-out;
  }
}
