@use 'base' as *;

@keyframes animate {
  0% {
    content: "A";
  }
  50% {
    color: $blue;
  }
  100% {
    content: "B";
  }
}

.foodshower {
  margin-top: -100px;
  background-color: red;
  position: absolute;
  width: 100vw;
  pointer-events: none;
}

.main-container-about {
  background-color: $blue;
  position: relative;
  display: flex;
  // justify-content: center;
  // height: 100vh;
  overflow: hidden;
  @media ($phone) {
    display: block;
    margin-top: 0;
    overflow: scroll;
  }

  .page-title,
  .social-links {
    position: relative;
    z-index: 5;
  }
  .about-container {
    height: 100vh;
    text-align: right;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    padding-right: 100px;
    // background-color: red;
    font-size: 14px;
    line-height: 1.5;
    justify-content: center;
    @media ($phone) {
      height: auto;
      margin-top: 40px;
      padding: 16px;
    }
    h5 {
      font-family: "Maison Neue Extended";
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
      margin: 0;
    }
    .top {
      position: relative;
      display: flex;
      // background-color: yellow;
      max-width: 80vw;
      // margin: auto;
      img {
        width: 90%;
      }
      @media ($phone) {
        flex-direction: column;
      }
      h1 {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $black;
        color: rgba(255, 255, 255, 0);
        position: relative;
        z-index: 20;
        font-family: "Maison Neue Extended";
        text-align: right;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-size: $unit * 3;
        margin: 0 -56px 24px 0;
        line-height: 1;
        @media ($small) {
          font-size: $unit * 2;
        }
      }
      .title-test {
        display: flex;
        justify-content: flex-end;
        margin: -16px -16px 0 0;
        @media ($phone) {
          display: none;
        }
      }
      ul {
        padding: 0;
        list-style-type: none;

        li {
          text-align: right;
          font-family: "Maison Neue Extended";
          font-weight: bold;
          letter-spacing: 2px;
          text-transform: uppercase;
          font-size: $unit * 3;
          line-height: 50px;
          padding: 0 16px 2px 0;
          margin: 0;
        }
      }
      .mask-title {
        overflow: hidden;
        height: 55px;
      }
      p {
        margin: 0px 0 16px 0;
        max-width: 560px;
        button {
          display: inline-block;
          width: auto;
          font-family: "Maison Neue Extended";
          font-weight: normal;
          font-size: 12px;
          background-color: rgba(255, 255, 255, 0);
          padding: 12px 24px;
          border: 1px solid $black;
          text-transform: uppercase;
          margin: 8px 0 0 0;
          cursor: pointer;
          &:hover {
            background-color: $black;
            color: $lightgray;
            cursor: pointer;
          }
        }
      }
      .left {
        display: flex;
        position: relative;
        @media ($phone) {
          margin: 40px 0 0 0;
        }
        img {
          max-width: 500px;
        }
        .buddy {
          position: absolute;
          width: 100%;
          height: 100%;
         
        }
        svg {
          z-index: 15;
          position: absolute;
          width: 90px;
          top: -24px;
          right: -24px;
          animation: rotate 6s infinite linear;
        }
      }
      .right {
        span {
          border-bottom: 1px solid $black;
          cursor: crosshair;

          // text-decoration: underline;
        }
      }
    }
    .bottom {
      display: flex;
      // justify-content: flex-start;
      font-size: 12px;
      // background-color: yellow;
      margin-top: 32px;
      margin-bottom: 80px;
      width: 100%;
      flex-wrap: wrap;
      .info {
        flex-basis: 25%;
        @media ($phone) {
          flex-basis: 40%;
          margin-left: 16px;
        }
        &.second {
          margin-top: 25px;
          @media ($phone) {
            margin-top: 21px;
          }
        }
        @media ($phone) {
          text-align: left;
          margin-bottom: 10px;
        }
      }
      ul {
        padding: 0;
        list-style-type: none;
        li {
          margin: 5px 0;
        }
      }
    }
  }
}
