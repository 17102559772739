@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

$green: #23f285;
$blue: #7b61e5;
$yellow: #ffdd44;
$red: #f35f20;
$black: #000000;
$lightgray: #fefefe;

$switchpurple: #6c5dd3;
$switchlightpurple: #e1d4fd;

$pawgreen: #00cabe;
$pawlightgreen: #bDe9e3;

$medred: #DE4023;
$medlightred: #ffd9d2;

$transparent: rgba(0, 0, 0, 0);

$unit: 16px;

$desktop: "min-width: 681px";
$phone: "max-width: 680px";
$small: "max-width: 1200px";

// TITLES

@font-face {
    font-family: 'Maison Neue Extended';
    src: url('../fonts/MaisonNeue-ExtendedBold.woff2') format('woff2'),
        url('../fonts/MaisonNeue-ExtendedBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Extended';
    src: url('../fonts/MaisonNeue-ExtendedBook.woff2') format('woff2'),
        url('../fonts/MaisonNeue-ExtendedBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

