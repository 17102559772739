@use 'base' as *;

video {
  cursor: pointer;
}

.desktop {
  @media (max-width: 600px) {
    display: none;
  }
}

.play-container {
  padding-top: $unit * 4;
  padding-left: $unit * 4;
  flex: 0 0 100%;
  // background-color: $yellow;
  width: calc(100vw - 128px);
  img {
    width: 100px;
  }
  overflow-y: scroll;
  @media ($phone) {
    display: none;
  }
  a {
    cursor: pointer;
    background-color: red;
  }
  .grid {
    display: flex;
    // background-color: tomato;
    padding: 0 40px 40px 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    span {
      margin: 2vw;
      // flex-basis: 33%;
      max-width: 290px;
    }
  }
}

.play-container-mobile {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  width: 100vw;
  height: 100%;
  overflow-y: scroll;
  padding-top: $unit * 2;

  .project {
    position: relative;
    &.last {
      margin-bottom: $unit;
    }
    h1 {
      font-family: "Maison Neue Extended";
      font-weight: bold;
      text-transform: uppercase;
      font-size: 32px;
      margin: $unit ($unit * 2);
    }
    span {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // background-color: red;
    }
    svg {
      width: 50px;
      margin: -5px 16px 0 0;
    }
    .line {
      display: block;
      width: 100vw;
      height: 1px;
      margin: 26px auto 26px auto;
      background-color: $black;
      &.one {
        &:after {
          font-size: 10px;
          content: "Website";
          margin: -12px 0 0 24px;
          position: absolute;
          padding: 6px 12px;
          background-color: $yellow;
          border-radius: 40px;
          border: 1px solid $black;
        }
      }
      &.two {
        &:after {
          font-size: 10px;
          content: "Website";
          margin: -12px 0 0 24px;
          position: absolute;
          padding: 6px 12px;
          background-color: $yellow;
          border-radius: 40px;
          border: 1px solid $black;
        }
      }
      &.three {
        &:after {
          font-size: 10px;
          content: "Browser Game";
          margin: -12px 0 0 24px;
          position: absolute;
          padding: 6px 12px;
          background-color: $yellow;
          border-radius: 40px;
          border: 1px solid $black;
        }
      }
      &.four {
        &:after {
          font-size: 10px;
          content: "Website";
          margin: -12px 0 0 24px;
          position: absolute;
          padding: 6px 12px;
          background-color: $yellow;
          border-radius: 40px;
          border: 1px solid $black;
        }
      }
      &.five {
        &:after {
          font-size: 10px;
          content: "5";
          margin: -12px 0 0 24px;
          position: absolute;
          padding: 6px 12px;
          background-color: $yellow;
          border-radius: 40px;
          border: 1px solid $black;
        }
      }
      &.six {
        &:after {
          font-size: 10px;
          content: "6";
          margin: -12px 0 0 24px;
          position: absolute;
          padding: 6px 12px;
          background-color: $yellow;
          border-radius: 40px;
          border: 1px solid $black;
        }
      }
    }
    .accordion-content {
      margin: 0 24px;
      p {
        font-size: 14px;
        line-height: 1.4;
        margin: 0 0 24px 0;
      }
    }
  }
  @media ($desktop) {
    display: none;
  }
}

.tooltip {
  color: $lightgray;
  font-size: 11px;
  line-height: 1.5;
  padding: 6px 10px 6px 10px;
  background-color: $black;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -40px;
  transition: all 0.2s ease-in-out;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $black transparent transparent transparent;
  }
}
