@use 'base' as *;

.intro-test {
  position: relative;
  z-index: 99;
}

.intro-text {
  text-align: center;
  color: $lightgray;
  font-size: $unit * 2;
  letter-spacing: 2px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  flex-direction: column;
  z-index: 99;
  text-transform: uppercase;
 h2{
  @media ($phone) {
    font-size: 32px;
    max-width: 300px
  }
 }
}

.container-black {
  background-color: $black;
  color: $lightgray;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  flex-direction: column;
  z-index: 98;
}

.container-red {
  background-color: $lightgray;
  color: $lightgray;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  flex-direction: column;
  z-index: 98;
}

.line {
  height: 6px;
  width: 0;
  background: $lightgray;
  margin-top: $unit / 2;
  margin-bottom: $unit;
  @media ($phone) {
    display: none;
  }
}

svg {
  @media ($phone) {
    width: 80%;
  }
}

.mask {
  overflow: hidden;
  // background-color: blue;
  margin: 0;
}

